window.dataLayer = window.dataLayer || [];
function gtag() {dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-TDY03JV1LX');

$(function () {
  $('[data-ga-submit]').on('submit',(e)=> {
    gtag('event', e.target.dataset.gaSubmit, { 'origin': e.target.dataset.gaValue});
  });

  $('[data-ga-click]').on('click', (e)=>{
    gtag('event', e.target.dataset.gaClick, {'origin': e.target.textContent});
  });
});